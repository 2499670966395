import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
  return http({
          url: http.adornUrl('/dataflow/olxcokrmanagedetail/list'),
          method: 'get',
          params: params
        })
} 

// 删除数据
export function deleteData(ids = []) {
	return http({
            url: http.adornUrl("/dataflow/olxcokrmanagedetail/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}

// 根据ID 获取
export function getById(id) {
	return http({
        url: http.adornUrl(`/dataflow/olxcokrmanagedetail/info/${id}`),
        method: "get"
        })
}
 
// 更新或新增
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/dataflow/olxcokrmanagedetail/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}